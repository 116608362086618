import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { Provider } from "react-redux";
import configureStore from "./store";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import config from "./config";
//import { IonApp } from '@ionic/react';
import { registerSW } from "virtual:pwa-register";

import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { defaultLocale, dynamicActivate } from "./i18n";
import { detect, fromStorage, fromNavigator } from "@lingui/detect-locale";
import { useEffect } from "react"; // Import useEffect

const intervalMS = 60 * 60 * 1000; // 1 hour

registerSW({
  immediate: true,
  onRegistered(r) {
    if (r)
      setInterval(() => {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        console.log("Checking for updates for SW...");
        r.update();
      }, intervalMS);
  },
});

if (config.app.host !== "localhost")
  Sentry.init({
    dsn: "https://bde1935b42df4485a1b66a353f8b7393@o1164148.ingest.sentry.io/4504162975940608",
    integrations: [],
    environment: config.app.host === "thipibo.com" ? "production" : "testing",
  });

const store = configureStore();
export { store };

const rootElement = document.getElementById("root");
const hideLoader = () => {};

const AppWithIntl = () => {
  useEffect(() => {
    // With this method we dynamically load the catalogs
    const language = detect(
      fromStorage("lang"),
      fromNavigator(),
      defaultLocale
    );
    dynamicActivate(language);
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <I18nProvider i18n={i18n}>
          <App onMount={hideLoader} />
        </I18nProvider>
      </BrowserRouter>
    </Provider>
  );
};

// React 18 uses createRoot instead of render
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<AppWithIntl />);
}
