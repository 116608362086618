/* eslint-disable lingui/no-unlocalized-strings */
import { fetcher } from "./components/utility";
import { eventGA } from "./components/TrackerGA";

const pushServerPublicKey =
  "BGw6_grzE_XxWcQ_vKRZxMealFU6wVACVBkG1Ka_L68SK04RBG_dujamKITdIztZuAVB3n1li3epwukm7gB7NjM";

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  return await Notification.requestPermission();
}

/**
 *
 */
function registerServiceWorker() {
  return navigator.serviceWorker.register("/sw.js", { scope: "/" });
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  //wait for service worker installation to be ready

  const serviceWorker = await navigator.serviceWorker.ready;

  if (!serviceWorker.pushManager) {
    //console.log("Push manager unavailable.", serviceWorker);
    return;
  }

  serviceWorker.pushManager
    .getSubscription()
    .then(function (existedSubscription) {
      if (existedSubscription === null) {
        try {
          //console.log("No subscription detected, make a request.");
          serviceWorker.pushManager
            .subscribe({
              applicationServerKey: pushServerPublicKey,
              userVisibleOnly: true,
            })
            .then(function (newSubscription) {
              //console.log("New subscription added.", newSubscription);
              sendSubscription(newSubscription);
            })
            .catch((error) => {
              console.error(error);
            });
        } catch (e) {
          console.error(e);
        }
      } else {
        //console.log("Existed subscription detected.", existedSubscription);
        sendSubscription(existedSubscription);
      }
    });
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}

function sendSubscription(subscription) {
  fetcher({
    path: "/api/subscribePush",
    credentials: true,
    method: "POST",
    body: subscription,
  })
    .then((res) => {
      eventGA("COMPORTAMENTO", "Attiva_Notifiche", "User_" + res.id);
    })
    .catch((e, res) => {
      //console.log("Error during Fetch:", e);
      eventGA("ERRORE", "Attiva_Notifiche", "User_" + res.id);
    });
}

export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
};
