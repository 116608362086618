import React from "react";
import MuiButton from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import { ButtonBaseProps, styled } from "@mui/material";

const PillButton = styled(MuiButton)(({ theme }) => ({
  borderRadius: 999,
  textTransform: "none",
}));

type ButtonProps = ButtonBaseProps & {
  href?: string;
  children: React.ReactNode;
  component?: React.ElementType;
  variant?: "text" | "outlined" | "contained";
  color?: "inherit" | "primary" | "secondary" | "success" | "error";
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  noWrapper?: boolean;
};

const Button = ({
  href,
  children,
  size = "small",
  fullWidth,
  noWrapper,
  ...props
}: ButtonProps) => {
  const linkProps = href ? { component: RouterLink, to: href } : {};
  const Wrapper = noWrapper ? React.Fragment : "div";
  return (
    <Wrapper>
      <PillButton
        component={href ? RouterLink : undefined}
        variant={props.variant ?? "contained"}
        color={props.color ?? "primary"}
        size={size ?? "medium"}
        {...linkProps}
        {...props}
        sx={{
          width: fullWidth ? "100%" : undefined,
          padding:
            size === "large"
              // eslint-disable-next-line lingui/no-unlocalized-strings
              ? "1rem 2.3rem"
              : size === "medium"
              // eslint-disable-next-line lingui/no-unlocalized-strings
              ? "0.5rem 1.5rem"
              // eslint-disable-next-line lingui/no-unlocalized-strings
              : "0.3rem 1rem",
          fontSize: size === "large" ? "1.2rem" : "1rem",
          fontWeight: size === "large" ? 600 : 500,
          ...(props.sx ?? {}),
        }}
      >
        {children}
      </PillButton>
    </Wrapper>
  );
};

export default Button;
