import React, { useRef } from "react";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import { Trans } from "@lingui/macro";

function HelpHomeProfilePopover(props) {
  const { show, setShow, placement = "auto" } = props;
  const target = useRef(null);

  return (
    <>
      <div ref={target}>{props.children}</div>
      <Overlay
        flip={true}
        placement={placement}
        target={target.current}
        show={show}
      >
        <Popover
          onClick={(e) => {
            e.preventDefault();
          }}
          id="popover-shadowed"
          className={props.className}
        >
          <Popover.Content>
            <div className="thipi-text-h5 pb-2">
              <Trans>
                Da questa sezione puoi gestire tutti i tuoi annunci.
              </Trans>
            </div>
            <div className="text-right pt-1">
              <button
                className="btn thipi-btn-color mini-btn mx-1"
                onClick={() => setShow(!show)}
              >
                <Trans>
                  Ho capito
                </Trans>
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
}
export default HelpHomeProfilePopover;
