// Login.js
import React, { Component } from "react";
import "./Login.css";
import "@assets/Feelmehome.css";
import { fetcher } from "../components/utility";
import { Trans, t } from "@lingui/macro";

class RecoverPass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mail: "",
      success: "null",
    };
  }
  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.recover();
    }
  };

  recover = () => {
    const mail = this.state.mail;
    fetcher({ path: "/api/forgot", method: "POST", body: { mail } })
      .then((res) => {
        if (!res.success) {
          this.setState({ success: "fail" });
        } else {
          this.setState({ success: "success" });
          //console.log("logged: " + this.state.logged + "|");
        }
      })
      .catch((e) => console.log("Error during Fetch:", e));
  };

  checkMailState = (event) => {
    this.setState({ mail: event.target.value });
  };

  render() {
    return (
      <div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 flex-grow-1">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <div className="thipi-text-h2 text-center">
                    {this.state.success === "null"
                      ? t`Inserisci la tua mail di registrazione `
                      : this.state.success === "fail"
                      ? t`Mail non trovata`
                      : t`Ti abbiamo inviato un'email per il ripristino 🔒`}
                  </div>
                </div>
                <div className="panel-body">
                  {this.state.success !== "success" && (
                    <form acceptCharset="UTF-8">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder={t`E-mail`}
                          name="email"
                          type="text"
                          autoComplete="email"
                          value={this.state.mail}
                          onChange={this.checkMailState}
                          onKeyPress={this.handleKeyPress}
                          /*required*/ autoFocus
                        />
                      </div>
                      <button
                        className="btn btn-lg btn-mycolor btn-block"
                        type="button"
                        onClick={() => {
                          this.recover();
                        }}
                      >
                        <Trans>Recupera Password</Trans>
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecoverPass;
