import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useServerConfig } from "../../../../contexts/serverConfig";
// import { IdentityProvider } from "../../../../gen/graphql-types";
// import { useSigninContext } from "../context";
// import { useIsomorphicLayoutEffect } from "usehooks-ts";
import { setUser, userSetNew } from "../actions/user";
import { onMessage, emitMessage } from "../actions/socket";
import { fetcher } from "./utility";
import {
  setHome,
  homeSetSlots,
  homeSetAds,
  homeSetNotifications,
} from "../actions/home";
import { eventGA, setIdGA } from "./TrackerGA";
import { setConversationPreview } from "../actions/conversation";

function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  // useIsomorphicLayoutEffect(() => {
  //   savedCallback.current = callback;
  // }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export default function GoogleButton() {
  // const { signin, setState } = useSigninContext();
  const divRef = useRef();
  const dispatch = useDispatch();
  // const { googleOAuthClientId } = useServerConfig();

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [google, setGoogle] = useState();
  const [googleIsLoading, setGoogleIsLoading] = useState(true);

  // const [client, setClient] = useState();

  const googleLoginFunction = useCallback(
    ({ credential }) => {
      fetcher({
        path: "/api/login_google",
        credentials: true,
        redirect: "follow",
        method: "POST",
        headers: {
          // eslint-disable-next-line lingui/no-unlocalized-strings
          "Access-Control-Allow-Origin": "*",
        },
        body: { credential },
      })
        .then((res) => {
          if (!res.success) {
            // eslint-disable-next-line lingui/no-unlocalized-strings
            console.log("login fallito");
          } else {
            if (res.newUser) {
              eventGA(
                "ACQUISIZIONI",
                // eslint-disable-next-line lingui/no-unlocalized-strings
                "Registrazione_google",
                // eslint-disable-next-line lingui/no-unlocalized-strings
                "User_" + res.user._id,
                res.user.city
              );
              res.user.new = true;
              res.user.pwaInstallSeen = true;
            }

            res.user.logged = true;
            setIdGA(res.user._id);
            //console.log("successo loggedin. Utente ID", res.user);
            res.user.tenants.forEach((tenant, index) => {
              if (tenant.user_id) {
                let user_tenant = res.user_tenants.find(
                  (x) => x._id == tenant.user_id
                );
                if (user_tenant) {
                  //console.log("USER TENANT SET", user_tenant);
                  let pickedUser = {};
                  pickedUser.value = user_tenant._id;
                  pickedUser.image = user_tenant.profile_image;
                  pickedUser.image_webp = user_tenant.profile_image_webp;
                  pickedUser.location = user_tenant.location;
                  pickedUser.age = user_tenant.age;
                  pickedUser.type = user_tenant.type;
                  pickedUser.label =
                    user_tenant.name + " " + user_tenant.surname;
                  res.user.tenants[index].pickedUser = pickedUser;
                  res.user.tenants[index].image = user_tenant.profile_image;
                  res.user.tenants[index].image_webp =
                    user_tenant.profile_image_webp;
                }
              }
            });
            dispatch(setUser(res.user));
            if (res.notifications != null) {
              //console.log("not in login  ", res.notifications);
              dispatch(homeSetNotifications(res.notifications));
            }

            if (res.home != null) {
              res.home.tenants.forEach((tenant, i_t) => {
                if (tenant.user_id) {
                  let home_tenant = res.home_tenants.find(
                    (x) => x._id == tenant.user_id
                  );
                  if (home_tenant) {
                    let pickedUser = {};
                    pickedUser.value = home_tenant._id;
                    pickedUser.image = home_tenant.profile_image;
                    pickedUser.image_webp = home_tenant.profile_image_webp;
                    pickedUser.location = home_tenant.location;
                    pickedUser.age = home_tenant.age;
                    pickedUser.type = home_tenant.type;
                    pickedUser.label =
                      home_tenant.name + " " + home_tenant.surname;
                    res.home.tenants[i_t].pickedUser = pickedUser;
                    res.home.tenants[i_t].image = home_tenant.profile_image;
                    res.home.tenants[i_t].image_webp =
                      home_tenant.profile_image_webp;
                  }
                }
              });
              if (res.ads != null) {
                res.home.advertises = res.ads;
              }
              dispatch(setHome(res.home));

              if (res.slotArray != null) {
                dispatch(homeSetSlots(res.slotArray));
              }
            }
            if (res.notifications != null) {
              dispatch(homeSetNotifications(res.notifications));
              //console.log("not log", res.notifications);
            }
            if (res.conversations != null) {
              dispatch(setConversationPreview(res.conversations));
              //console.log("conv log", res.conversations);
            }
            if (res.home != null) {
              dispatch(emitMessage("login_home", res.home._id));
              dispatch(emitMessage("login_user", res.user._id));
            } else dispatch(emitMessage("login_user", res.user._id));
          }
        })
        // eslint-disable-next-line lingui/no-unlocalized-strings
        .catch((e) => console.log("Error during Fetch:", e));
    },
    [dispatch]
  );

  useInterval(
    () => {
      if (typeof window !== "undefined" && window.google) {
        setGoogle(window.google);
        setGoogleIsLoading(false);
      }
    },
    googleIsLoading ? 100 : null
  );

  useEffect(() => {
    if (scriptLoaded) return undefined;
    const width = Math.floor(
      Math.min(410, document.getElementById("facebookButton")?.offsetWidth)
    );
    //console.log("UE WIDTH", width);

    const initializeGoogle = () => {
      //console.log("INITIALIZING GOOGLE");
      if (!window.google || scriptLoaded) return;

      setScriptLoaded(true);
      // const cl = window.google.accounts.oauth2.initTokenClient({
      //   client_id:
      //     "830046400557-f7au6r4qvkn6jcku7r7l2vekq2irhqsu.apps.googleusercontent.com",
      //   scope:
      //     "https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/userinfo.email  https://www.googleapis.com/auth/userinfo.profile",
      //   callback: googleLoginFunction,
      // });
      // setClient(cl);
      window.google.accounts.id.initialize({
        client_id:
          "830046400557-f7au6r4qvkn6jcku7r7l2vekq2irhqsu.apps.googleusercontent.com",
        callback: googleLoginFunction,
      });
      window.google.accounts.id.renderButton(divRef.current, {
        theme: "outline",
        size: "medium",
        text: "continue_with",
        logoAlignment: "left",
        width: width + "px",
      });
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGoogle;
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body")?.appendChild(script);

    return () => {
      window.google?.accounts?.id?.cancel?.();
      document.getElementById("google-client-script")?.remove();
    };
  }, [scriptLoaded, googleLoginFunction]);

  return (
    <>
      {/* <div className="g_id_signin" data-type="standard"></div> */}
      <div ref={divRef} />
      {/* <button onClick={() => client.requestAccessToken()}>Login Google</button> */}
    </>
  );
}
