import React from "react";
import { fetcher } from "../utility";
import { connect } from "react-redux";
import Loader from "../Loader";
import { withRouter } from "react-router-dom";
import { userSettings } from "../../actions/user";
import { compose } from "redux";
import Modal from "react-bootstrap/Modal";
import { Trans } from "@lingui/macro";

const mapStateToProps = (state) => {
  return {
    user: state.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userSettings: (settings) => dispatch(userSettings(settings)),
  };
};

class NotVerifiedModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "continuare",
      loading: false,
      success: false,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.outer = document.createElement("div");
    this.outer.style.visibility = "hidden";
    this.outer.id = "NotVerifiedModalButton";
    this.outer.type = "button";
    this.outer.addEventListener("click", (e) => {
      if (e.target.value) this.setState({ text: e.target.value.text });
      this.checkIfVerified(e.target.value.cb);
    });
    document.body.appendChild(this.outer);
  }

  componentWillUnmount() {
    this.outer.parentNode.removeChild(this.outer);
  }

  checkIfVerified = (cb) => {
    if (this.props.user.active) {
      cb?.call();
      return;
    }
    fetcher({
      path: "/api/userCheckVerified",
      credentials: true,
      method: "POST",
    })
      .then((res) => {
        if (res.success) {
          this.props.userSettings({ mail: this.props.user.mail, active: true });
          cb?.call();
          setTimeout(() => {
            if (this.state.isOpen) this.toggleModal(false);
          }, 5000);
        } else this.toggleModal(true);
      })
      .catch((e) => console.log("Error during Fetch:", e));
  };

  sendVerifyMail = () => {
    //MANDO LA MODIFICA AL SERVER
    this.setState({ loading: true });
    fetcher({
      path: "/api/userSendVerifyMail",
      credentials: true,
      method: "POST",
    })
      .then((res) => {
        if (!res.success) {
          //console.log("Errore", res.error);
          this.setState({ success: false, loading: false });
        } else {
          //console.log("Successo!", res);
          this.setState({ success: true, loading: false });
        }
      })
      .catch((e) => console.log("Error during Fetch:", e));
  };

  toggleModal = (bool) => {
    this.setState({ isOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        this.setState({ isOpen: false });
        window.onpopstate = null;
      };
    } else {
      if (window.history.state === "popup-open") window.history.back();
    }
  };

  showLoadingIcon = () => {
    return (
      <div className="row justify-content-md-center">
        <div className="col text-center">
          <Loader />
        </div>
      </div>
    );
  };

  render() {
    return (
      <Modal
        show={this.state.isOpen}
        onHide={() => this.toggleModal(false)}
        dialogClassName="modal-dialog-centered modal-log-dialog"
      >
        {!this.props.user.active ? (
          <>
            <div className="modal-body text-center">
              <div className="modal-header no-borders">
                <div className=" thipi-text-h2 pb-2">
                  <Trans>
                    Emh... potresti prima verificare la tua email?
                  </Trans>
                </div>
                <button
                  type="button"
                  className="close modal-close position-absolute modal-close-top-right"
                  onClick={() => this.toggleModal(false)}
                >
                  ×
                </button>
              </div>
              {this.state.loading ? (
                this.showLoadingIcon()
              ) : !this.state.success ? (
                <div className="thipi-text-h4">
                  <Trans>Per</Trans> {this.state.text} <Trans>attiva il tuo account al link che
                  abbiamo inviato a </Trans><b>{this.props.user.mail}</b>
                  <br />
                </div>
              ) : (
                <div className="thipi-text-h4">
                  <Trans>La mail di verifica è stata reinviata a</Trans>{" "}
                  <b>{this.props.user.mail}</b>
                </div>
              )}
            </div>
            <div className="modal-footer">
              {!this.state.success ? (
                <>
                  <Trans>Vuoi ricevere la mail... di nuovo?</Trans>
                  <br />
                  <button
                    className="btn thipi-btn-color mini-btn mx-1"
                    onClick={this.sendVerifyMail}
                  >
                    <Trans>
                      Reinvia
                    </Trans>
                  </button>
                </>
              ) : (
                <button
                  className="btn thipi-btn-color mini-btn mx-1"
                  onClick={() => this.toggleModal(false)}
                >
                  <Trans>
                    Chiudi
                  </Trans>
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="modal-body text-center">
              <div className="modal-header no-borders">
                <div className=" thipi-text-h2 pb-2"><Trans>Account verificato</Trans></div>
                <button
                  type="button"
                  className="close modal-close position-absolute modal-close-top-right"
                  onClick={() => this.toggleModal(false)}
                >
                  ×
                </button>
              </div>
              <div className="thipi-text-h4">
                <Trans>Il tuo account è stato correttamente verificato, puoi</Trans>{" "}
                {this.state.text}
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn thipi-btn-color mini-btn mx-1"
                onClick={() => this.toggleModal(false)}
              >
                <Trans>
                  Chiudi
                </Trans>
              </button>
            </div>
          </>
        )}
      </Modal>
    );
  }
}
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NotVerifiedModal);
