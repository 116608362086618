import React from "react";
import Step2Img from "@assets/img/step1whyreg.svg";
import Step1Img from "@assets/img/step2whyreg.svg";
import Step3Img from "@assets/img/step3whyreg.svg";
import { withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { compose } from "redux";
import { connect } from "react-redux";
import { setInappWarning } from "../../actions/general";
import { Link } from "react-router-dom";
import { eventGA } from "../TrackerGA";
import { Trans } from "@lingui/macro";

const mapDispatchToProps = (dispatch) => {
  return {
    setInappWarning: (read) => dispatch(setInappWarning(read)),
  };
};

const mapStateToProps = (state) => {
  return {
    read: state.General.inAppWarning,
  };
};

class WhyRegisterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: false,
      success: false,
    };
  }

  componentDidMount() {
    this.outer = document.createElement("div");
    this.outer.style.visibility = "hidden";
    this.outer.id = "WhyRegisterModalButton";
    this.outer.type = "button";
    this.outer.addEventListener("click", (/*e*/) => {
      this.toggleModal(true);
    });
    document.body.appendChild(this.outer);

    //TEST
    //this.outer.click();
  }

  componentWillUnmount() {
    this.outer.parentNode.removeChild(this.outer);
  }

  loginClick = () => {
    eventGA("COMPORTAMENTO", "Login_da_Why_Register", "New_User");
    this.toggleModal(false);
    setTimeout(() => {
      if (document.getElementById("loginNavbarButton"))
        document.getElementById("loginNavbarButton").click();
    }, 100);
  };

  toggleModal = (bool) => {
    this.setState({ isOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        this.setState({ isOpen: false });
        window.onpopstate = null;
      };
    } else {
      if (window.history.state === "popup-open") window.history.back();
    }
  };

  render() {
    return (
      <Modal
        show={this.state.isOpen}
        className="top"
        onHide={() => this.toggleModal(false)}
        dialogClassName="modal-dialog pt-4"
      >
        <div className="modal-body">
          <div className="row pb-3">
            <div className="col-3">
              <Step1Img
                className="svg-thipibo-color"
                height="4em"
                width="100%"
              />
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <div className="thipi-text-h6-b pl-1 pb-2">
                    <Trans>
                      Non preoccuparti, qui su Thipibo gli annunci sono solo la
                      prima parte della ricerca. <br />
                      Da questa pagina puoi inviare richieste tramite ping e
                      superping. <br /> Ma c'è un altro modo per trovare casa.
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-3">
              <Step2Img
                className="svg-thipibo-color"
                height="4em"
                width="100%"
              />
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <div className="thipi-text-h6-b pl-1 pb-3">
                    <Trans>
                      I proprietari hanno accesso ad una pagina da cui possono
                      visualizzare direttamente i profili degli utenti e
                      contattarli direttamente.
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <Step3Img
                className="svg-thipibo-color"
                height="4em"
                width="100%"
              />
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <div className="thipi-text-h6-b pl-1 pb-2">
                    <Trans>
                      Potrai essere invitato sia dai proprietari attuali che da
                      tutti quelli che aggiungeranno annunci in futuro. <br /> Per
                      farlo registrati, compila il tuo profilo e torna a trovarci!
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-right">
              <button
                className="btn btn-myoutline thipi-btn-color mini-btn mx-1"
                onClick={this.loginClick}
              >
                <Trans>
                  Registrati
                </Trans>
              </button>
              <Link className="nounderline" to="/come-funziona">
                <button
                  className="btn thipi-btn-color mini-btn mx-1"
                  onClick={() => this.toggleModal(false)}
                >
                  <Trans>
                    Scopri come funziona
                  </Trans>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(WhyRegisterModal);
