import React, { useState } from "react";
import {
  InputAdornment,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import "./CitySelect.css";
import { useSelector } from "react-redux";
import { isPartner } from "./utility";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { t } from "@lingui/macro";

export default function CitySelect({
  city = "",
  setCity,
  onClick,
  small = false,
  displayEmpty = true,
  fullWidth = false,
  rounded = true,
  shadow = true,
}) {
  const [open, setOpen] = useState(false);
  const cities = useSelector((state) => state.General.cities);
  const cityElem = cities.find((x) => x.value === city) ?? null;
  const [inputValue, setInputValue] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(false);

  const handleOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(false);
    setIsReadOnly(false);
  };

  return (
    <div
      className={`row city-selector ${small ? "small" : ""} ${
        fullWidth ? "mx-0 w-100" : ""
      } ${rounded ? "rounded" : ""} ${shadow ? "shadow" : ""}`}
    >
      {/* <FormControl className="ml-2 ">
          <InputLabel>Città</InputLabel> */}
      <Autocomplete
        size={small ? "small" : "medium"}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        sx={{ flex: 1 }}
        autoFocus={false}
        fullWidth={fullWidth}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t`Seleziona città`}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              readOnly: isReadOnly,
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-map-marker-alt thipi-text-color" />
                </InputAdornment>
              ),
              endAdorment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      setIsReadOnly(true);
                      handleOpen(e);
                    }}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                  {params.InputProps.endAdornment}
                </InputAdornment>
              ),
              disableUnderline: true, // <== added this
            }}
          />
        )}
        disableUnderline
        getOptionLabel={(option) => option.label}
        options={cities}
        value={cityElem}
        inputValue={inputValue}
        groupBy={(option) =>
          !isPartner() ? option.group ?? t`Altre` : undefined
        }
        onInputChange={(_e, value) => setInputValue(value ?? "")}
        displayEmpty={displayEmpty}
        variant="standard"
        className="ml-2"
        onChange={(_e, option) => {
          if (!option || option === city || option === "") return;
          setCity(option.value);
        }}
        classes={{
          listbox: "custom-scrollbar-noMobile",
          groupLabel: "font-weight-bold",
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      />
      {/* </FormControl> */}
      {onClick && (
        <div className="my-auto">
          <IconButton
            size="small"
            className="roundIcon orange ml-auto p-2"
            aria-label="search"
            onClick={city ? onClick : () => setOpen(true)}
          >
            <i className="fas fa-search text-white"></i>
          </IconButton>
        </div>
      )}
    </div>
  );
}
