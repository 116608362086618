import React from "react";
import ChromeImg from "@assets/img/chrome.svg";
import { withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { compose } from "redux";
import { connect } from "react-redux";
import { setInappWarning } from "../../actions/general";
import { Trans } from "@lingui/macro";

const mapDispatchToProps = (dispatch) => {
  return {
    setInappWarning: (read) => dispatch(setInappWarning(read)),
  };
};

const mapStateToProps = (state) => {
  return {
    read: state.General.inAppWarning,
  };
};

class InAppBrowserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: false,
      success: false,
    };
  }

  componentDidMount() {
    this.outer = document.createElement("div");
    this.outer.style.visibility = "hidden";
    this.outer.id = "InAppModalButton";
    this.outer.type = "button";
    this.outer.addEventListener("click", (/*e*/) => {
      this.toggleModal(true);
    });
    document.body.appendChild(this.outer);

    //TEST
    //this.outer.click();
  }

  componentWillUnmount() {
    this.outer.parentNode.removeChild(this.outer);
  }

  isFacebookApp = () => {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return (
      ua.indexOf("FBAN") > -1 ||
      ua.indexOf("FBAV") > -1 ||
      ua.indexOf("Instagram") > -1
    );
  };

  toggleModal = (bool) => {
    if ((!this.props.read && this.isFacebookApp()) || !bool) {
      this.props.setInappWarning(true);
      this.setState({ isOpen: bool });
      if (bool) {
        window.history.pushState("popup-open", null, "");
        window.onpopstate = (e) => {
          e.preventDefault();
          this.setState({ isOpen: false });
          window.onpopstate = null;
        };
      } else {
        if (window.history.state === "popup-open") window.history.back();
      }
    }
  };

  render() {
    return (
      <Modal
        show={this.state.isOpen}
        onHide={() => this.toggleModal(false)}
        dialogClassName="modal-dialog-centered modal-inApp"
      >
        <div className="modal-body">
          <div className="row">
            <div className="col-3">
              <ChromeImg
                className="svg-thipibo-color"
                height="4em"
                width="100%"
              />
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <div className="thipi-text-h5-b pb-2">
                    <Trans>
                      Per una migliore esperienza vieni a trovarci su un browser
                      esterno
                    </Trans>
                  </div>
                </div>
                <div className="col-12 thipi-text-h6">
                  <Trans>
                    Cerca www.thipibo.com su Chrome o Safari
                  </Trans>
                </div>
              </div>
            </div>

            <div className="col-12 text-right">
              <button
                className="btn thipi-btn-color mini-btn mx-1"
                onClick={() => this.toggleModal(false)}
              >
                <Trans>
                  Ok
                </Trans>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(InAppBrowserModal);
