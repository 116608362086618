// NotificationMenu.js
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { fetcher } from "./utility";
import ImgBell from "@assets/img/notification.svg";
import { connect } from "react-redux";
import {
  homeChangeSlots,
  homeSetNotifications,
  homeAddNotification,
  homeReadNotifications,
  homeSetNotificationState,
  homeAddUserInSlot,
} from "../actions/home";
import { onMessage, emitMessage, leave } from "../actions/socket";
import { setConversationPreview } from "../actions/conversation";
import { setNread } from "../actions/general";
import produce from "immer";
import RefreshAtEnd from "./RefreshAtEnd";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import {
  reload,
  showLoadingIcon,
  convertNotif,
  setAsRead,
  changeReqState,
  renderNotifications,
} from "./NotificationUtility";
import { Badge } from "@mui/material";
import { t, Trans } from "@lingui/macro";

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const mapDispatchToProps = (dispatch) => {
  return {
    onMessage: (event, handle) => dispatch(onMessage(event, handle)),
    leave: (event) => dispatch(leave(event)),
    emitMessage: (event, handle) => dispatch(emitMessage(event, handle)),
    homeChangeSlots: (slots) => dispatch(homeChangeSlots(slots)),
    homeSetNotifications: (notifications) =>
      dispatch(homeSetNotifications(notifications)),
    homeSetNotificationState: (id, state) =>
      dispatch(homeSetNotificationState(id, state)),
    homeAddNotification: (notification) =>
      dispatch(homeAddNotification(notification)),
    homeReadNotifications: () => dispatch(homeReadNotifications()),
    homeAddUserInSlot: (slot) => dispatch(homeAddUserInSlot(slot)),
    setConversationPreview: (previews) =>
      dispatch(setConversationPreview(previews)),
    setNread: (nread) => dispatch(setNread(nread)),
  };
};

const mapStateToProps = (state) => {
  return {
    home: state.Home,
    user: state.User,
    inbox: state.Inbox,
  };
};

class NotificationMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: false,
      notification: [],
      nsize: 0,
      nread: 0,
      isInitial: false,
      loading: false,
      timestart: 0,
      stop: false,
      currentNot: {},
      currentMotivation: "",
      marked: false,
      isAcceptOpen: false,
      isRefuseOpen: false,
    };
  }

  componentDidMount() {
    reload.call(this, false);
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.props.onMessage("New_notification", (notif) => {
      if (notif.state !== "refused") this.props.homeAddNotification(notif);
      else {
        this.props.homeSetNotificationState(notif.id, notif.state);
      }
      if (notif.state === "pending") {
        var slot = {
          hour: notif.hour,
          month: notif.month,
          day: notif.day,
          minute: notif.minute,
          state: notif.state,
          id: notif.user_id,
        };
        this.props.homeAddUserInSlot(slot);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      reload.call(this, false);
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    this.props.leave("New_notification");
  }

  toggleAcceptModal = (bool) => {
    this.setState({ isAcceptOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        this.setState({ isAcceptOpen: false });
        window.onpopstate = null;
      };
    } else {
      if (window.history.state === "popup-open") window.history.back();
    }
  };

  toggleRefuseModal = (bool) => {
    this.setState({ isRefuseOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        this.setState({ isRefuseOpen: false });
        window.onpopstate = null;
      };
    } else {
      if (window.history.state === "popup-open") window.history.back();
    }
  };

  reloadNotif = (/*notif*/) => {
    if (!this.state.stop) {
      //  console.log("in reload", notif);
      var user_id = this.props.user._id;
      var home_id = this.props.home._id;
      var profile_image = this.props.user.profile_image;
      var profile_image_webp = this.props.user.profile_image_webp;
      var timestamp_home = this.props.user.timestamp_home;
      var num = 6;
      var timestart = this.state.timestart;
      this.setState({ isInitial: true });
      fetcher({
        path: "/api/loadNotifications",
        credentials: true,
        method: "POST",
        body: {
          user_id,
          home_id,
          profile_image,
          profile_image_webp,
          timestamp_home,
          num,
          timestart,
        },
      })
        .then((res) => {
          if (!res.success) console.log("load notif fallito");
          else {
            var notifs = res.notifications;
            var nsize = this.size(notifs);
            if (nsize > 0) {
              notifs = this.state.notification.concat(res.notifications);
              this.props.homeSetNotifications(notifs);
              this.setState({ notification: notifs });
              this.setState({ nsize: nsize });
              //   console.log("notif ", notifs);
            }
          }
          this.setState({ isInitial: false });
          if (this.size(res.notifications) > 0) {
            var timestamp = timestart;
            var i;
            for (i = nsize - 1; i >= 0; i--) {
              if (res.notifications[i] && timestamp == timestart)
                timestamp = res.notifications[i].timestamp;
            }
            this.setState({
              timestart: timestamp,
            });
          } else this.setState({ stop: true });
          //this.setState({logged: res.success});
          //console.log("logged: " + this.state.logged + "|");
        })
        .catch((e) => console.log("Error during Fetch:", e));
    }
  };

  size = (obj) => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  handleCurrentNot = (not) => {
    this.setState({ currentNot: not });
  };

  handleNoteChange = (event /*, index*/) => {
    var motivation = event.target.value;
    this.setState({ currentMotivation: motivation });
  };

  render() {
    const { selected, view } = this.props;
    return (
      <React.Fragment>
        <Dropdown
          className={
            view === "desktop"
              ? "dropdown navbar-text  nav-item nav-item-text d-none d-md-block"
              : "d-none"
          }
          onClick={() => setAsRead.call(this)}
        >
          <Dropdown.Toggle as={CustomToggle} id="notifications-dropdown">
            <Badge badgeContent={this.state.nread} color="primary" max={5}>
              <ImgBell
                className={
                  "cursor-pointer " +
                  (selected ? "svg-thipibo-color" : "svg-thipibo-gray-color")
                }
                width="25"
                height="25"
                alt=""
              />
            </Badge>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu notification-dropdown-menu custom-scrollbar not-ul"
            aria-label="notifications-dropdown"
          >
            <h6 className="dropdown-header text-thipibo-gray-color border-bottom">
              <Trans>
                Notifiche
              </Trans>
            </h6>

            {this.state.nsize === 0 &&
              this.props.user.logged &&
              !(
                (this.state.isInitial && this.props.user.logged) ||
                this.state.loading
              ) && (
                <div
                  id="notificationsLoader"
                  className="dropdown-item dropdown-notification"
                >
                  <div className="thipi-text-h4 my-2 text-center">
                    <Trans>
                      Nessuna nuova notifica
                    </Trans>
                  </div>
                </div>
              )}

            {/*{(!this.props.user.seeking) && (
                this.renderNotSeekingRequests()
              )}

              {(this.props.user.seeking) && (
                this.renderSeekingRequests()
              )}*/}
            <li>
              <ul
                id="dropdown-menu"
                className="not-ul custom-scrollbar text-thipibo-gray-color"
              >
                {renderNotifications.call(this)}
                {!this.state.loading && (
                  <RefreshAtEnd
                    root="dropdown-menu"
                    onVisible={() => this.reloadNotif()}
                  />
                )}
                <div style={{ height: "1px" }}></div>
                <div className="row">
                  <div className="col-12" id="loadingIcon">
                    {showLoadingIcon.call(this)}
                  </div>
                </div>
              </ul>
            </li>
            <li>
              <Link className="nounderline" to={"/notifications"}>
                <Dropdown.Item
                  className="dropdown-notification-all"
                  href="/notifications"
                >
                  <Trans>
                    Mostra tutte le notifiche
                  </Trans>
                </Dropdown.Item>
              </Link>
            </li>
          </Dropdown.Menu>
        </Dropdown>

        <Modal
          show={this.state.isAcceptOpen}
          onHide={() => this.toggleAcceptModal(false)}
          dialogClassName="modal-lg"
          backdrop={false}
        >
          <div className="modal-body thipi-text-h5">
            <Trans>
              Vuoi affittare questa stanza/casa? Se confermi sarai automaticamente
              registrato come nuovo inquilino!
            </Trans>
          </div>
          <div className="modal-footer">
            <div className="col-auto h-100" cursor="pointer">
              <button
                className="thipi-btn-color h-50"
                type="button"
                aria-hidden="true"
                onClick={(event) => {
                  changeReqState.call(
                    this,
                    event,
                    this.state.currentNot,
                    "tenant-accepted",
                    ""
                  );
                  this.toggleAcceptModal(false);
                }}
              >
                <Trans>
                  Conferma
                </Trans>
              </button>
            </div>
            <div className="col-auto h-100" cursor="pointer">
              <button
                className="btn-myoutline h-50"
                type="button"
                aria-hidden="true"
                onClick={() => this.toggleAcceptModal(false)}
              >
                <Trans>
                  Annulla
                </Trans>
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          show={this.state.isRefuseOpen}
          onHide={() => this.toggleRefuseModal(false)}
          dialogClassName="modal-lg"
          backdrop={false}
        >
          <div className="modal-body">
            <div className="row">
              <div className="col-12 thipi-text-h5">
                <Trans>
                  Se rifiuti l&apos;invito non potrai più accettare la stanza per
                  questo annuncio. Aiutaci a capire come migliorare
                  l&apos;annuncio lasciando una breve motivazione
                </Trans>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <textarea
                  className="form-control"
                  type="input"
                  maxLength="1500"
                  name="bday"
                  rows="1"
                  onChange={(event) => this.handleNoteChange(event)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="col-auto h-100" cursor="pointer">
              <button
                className="thipi-btn-color h-50"
                type="button"
                aria-hidden="true"
                onClick={(event) => {
                  changeReqState.call(
                    this,
                    event,
                    this.state.currentNot,
                    "tenant-refused",
                    this.state.currentMotivation
                  );
                  this.toggleRefuseModal(false);
                }}
              >
                <Trans>
                  Conferma
                </Trans>
              </button>
            </div>
            <div className="col-auto h-100" cursor="pointer">
              <button
                className="btn-myoutline h-50"
                type="button"
                aria-hidden="true"
                onClick={() => this.toggleRefuseModal(false)}
              >
                <Trans>
                  Annulla
                </Trans>
              </button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationMenu);
