import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import LogoScritta from "@assets/brand/logo-scritta.svg";
import { Link, withRouter } from "react-router-dom";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import RoundImage from "./RoundImage";
import { eventGA } from "./TrackerGA";
import { isPartner } from "./utility";
import LanguageSelector from "./LanguageSelector";
import { Trans } from "@lingui/macro";

const mapStateToProps = (state) => {
  return {
    user: state.User,
  };
};

class NavbarModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      loading: false,
      success: false,
      isOpen: false,
    };
  }

  componentDidMount() {
    this.outer = document.createElement("div");
    this.outer.style.visibility = "hidden";
    this.outer.id = "NavbarModalButton";
    this.outer.type = "button";
    this.outer.addEventListener("click", (e) => {
      if (e.target.value) this.setState({ text: e.target.value });
      this.toggleModal(true);
    });
    document.body.appendChild(this.outer);

    //TEST
    //this.outer.click();
  }

  componentWillUnmount() {
    this.outer.parentNode.removeChild(this.outer);
  }

  toggleModal = (bool) => {
    this.setState({ isOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        this.setState({ isOpen: false });
        window.onpopstate = null;
      };
    } else {
      if (window.history.state === "popup-open") window.history.back();
    }
  };

  render() {
    const { selected, logged, home_id, logout, deferredPrompt, city } =
      this.props;
    const { profile_image, profile_image_webp } = this.props.user;
    return (
      <SwipeableDrawer
        open={this.state.isOpen}
        onOpen={() => {}}
        onClose={() => this.toggleModal(false)}
        disableSwipeToOpen
        //onOpen={() => this.toggleModal(true)}
        anchor="right"
        //dialogClassName="modal-right-side modal-full-right modal-log-dialog"
      >
        <div className="al-center shadow-sm">
          <div className="navbar-text">
            <LogoScritta
              className="svg-thipibo-color"
              height="40"
              width="109"
            />
          </div>
        </div>
        <div className="modal-body mt-0 pt-1">
          <ul className="navbar-nav ml-auto">
            {logged && (
              <>
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="modal-body my-0 py-0">
                        <li
                          onClick={() => this.toggleModal(false)}
                          className={"nav-item nav-item-text "}
                        >
                          <Link to="/user-profile">
                            <div
                              className={
                                "navbar-text pt-2 pb-0 " +
                                (selected === "UPR"
                                  ? " navbar-text-active "
                                  : "")
                              }
                            >
                              <Trans>Profilo</Trans>
                            </div>
                          </Link>
                        </li>
                        <li
                          onClick={() => this.toggleModal(false)}
                          className={"nav-item nav-item-text "}
                        >
                          <Link to="/settings">
                            <div
                              className={
                                "navbar-text " +
                                (selected === "STT"
                                  ? " navbar-text-active "
                                  : "")
                              }
                            >
                              <Trans>Impostazioni</Trans>
                            </div>
                          </Link>
                        </li>
                      </div>
                    </div>
                  </div>
                  <div
                    className="m-auto"
                    onClick={() => this.toggleModal(false)}
                  >
                    <Link className="m-auto" to="/user-profile">
                      <RoundImage
                        className="ml-0 pl-0"
                        diameter="3.5em"
                        src={profile_image}
                        srcWebp={profile_image_webp}
                        orange={false}
                        variant="small"
                      />
                    </Link>
                  </div>
                </div>
                <Divider />
              </>
            )}
            {!isPartner() && (
              <>
                <li
                  onClick={() => this.toggleModal(false)}
                  className={"nav-item nav-item-text"}
                >
                  <Link to="/come-funziona">
                    <div
                      className={
                        "navbar-text " +
                        (selected === "HIW" ? " navbar-text-active " : "")
                      }
                    >
                      <Trans>Come funziona</Trans>
                    </div>
                  </Link>
                </li>

                <li
                  onClick={() => this.toggleModal(false)}
                  className={"nav-item nav-item-text "}
                >
                  <Link to={"/affitto/" + city}>
                    <div
                      className={
                        "navbar-text " +
                        (selected === "SRA" ? " navbar-text-active " : "")
                      }
                    >
                      <Trans>Trova casa</Trans>
                    </div>
                  </Link>
                </li>

                <li
                  onClick={() => this.toggleModal(false)}
                  className={"nav-item nav-item-text "}
                >
                  <Link to={"/cerca-inquilino/" + city}>
                    <div
                      className={
                        "navbar-text " +
                        (selected === "SRT" ? " navbar-text-active " : "")
                      }
                    >
                      <Trans>Trova coinquilino</Trans>
                    </div>
                  </Link>
                </li>
              </>
            )}

            {/* {logged && (
              <li
                onClick={() => this.toggleModal(false)}
                className={"nav-item nav-item-text "}
              >
                <Link to="/agenda">
                  <div
                    className={
                      "navbar-text " +
                      (selected === "AGN" ? " navbar-text-active " : "")
                    }
                  >
                    Agenda
                  </div>
                </Link>
              </li>
            )} */}

            {home_id != -1 && !isPartner() && (
              <li
                onClick={() => this.toggleModal(false)}
                className={"nav-item nav-item-text "}
              >
                <Link to="/home-profile">
                  <div
                    className={
                      "navbar-text " +
                      (selected === "HPR" ? " navbar-text-active " : "")
                    }
                  >
                    <Trans>I miei annunci</Trans>
                  </div>
                </Link>
              </li>
            )}

            {home_id == -1 && !isPartner && (
              <li
                onClick={() => this.toggleModal(false)}
                className={"nav-item nav-item-text "}
              >
                <Link to="/why-create-home">
                  <div
                    className={
                      "navbar-text " +
                      (selected === "CRH" ? " navbar-text-active " : "")
                    }
                  >
                    <Trans>Pubblica annuncio</Trans>
                  </div>
                </Link>
              </li>
            )}

            {!logged && (
              <li
                className={"nav-item nav-item-text "}
                onClick={() => {
                  setTimeout(
                    () => document.getElementById("loginNavbarButton").click(),
                    500
                  );
                  this.toggleModal(false);
                }}
              >
                <div
                  className={
                    "navbar-text " +
                    (selected === "LOG" ? " navbar-text-active " : "")
                  }
                >
                  <Trans>Accedi</Trans>
                </div>
              </li>
            )}

            {logged && (
              <>
                <div className="">
                  {deferredPrompt && (
                    <>
                      <Divider />
                      <li className="nav-item nav-item-text">
                        <div className="row navbar-text text-left">
                          <div className="col-12 thipi-text-h4-b">
                            <Trans>
                              Utilizza i nostri servizi
                              <br />
                              più velocemente
                            </Trans>
                          </div>
                          <div className="col-12 mt-2">
                            <button
                              type="button"
                              className="btn btn-sm thipi-btn-color-rounded"
                              onClick={() => {
                                deferredPrompt.prompt();
                                this.toggleModal(false);
                                eventGA(
                                  "COMPORTAMENTO",
                                  "PWA_fromNavModal",
                                  "User_" + this.props.user._id,
                                  this.props.user.city
                                );
                              }}
                            >
                              <Trans>Salva in Home</Trans>
                            </button>
                          </div>
                        </div>
                      </li>
                      <Divider />
                    </>
                  )}
                  <li
                    className="nav-item nav-item-text cursor-pointer"
                    onClick={() => {
                      logout();
                      this.toggleModal(false);
                    }}
                  >
                    <div className="navbar-text">
                      <Trans>Esci</Trans>
                    </div>
                  </li>
                </div>
              </>
            )}
            <li className="nav-item nav-item-text cursor-pointer">
              <LanguageSelector />
            </li>
          </ul>
        </div>
      </SwipeableDrawer>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps))(NavbarModal);
