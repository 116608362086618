// Login.js
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import "./Login.css";
import "@assets/Feelmehome.css";
import facebookIcon from "@assets/img/facebook.png";
import facebookIconWebp from "@assets/img/facebook.webp";
import { connect } from "react-redux";
import { setUser, userSetNew } from "../actions/user";
import { onMessage, emitMessage } from "../actions/socket";
import {
  setHome,
  homeSetSlots,
  homeSetAds,
  homeSetNotifications,
} from "../actions/home";
import { setConversationPreview } from "../actions/conversation";
import { fetcher } from "../components/utility";
import { setIdGA } from "../components/TrackerGA";
import {
  fbLogIn,
  fb,
  isFacebookApp,
  facebookRedirect,
} from "../components/facebookUtility";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  adaptV4Theme,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import GoogleButton from "../components/GoogleButton";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { t, Trans } from "@lingui/macro";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#ff7417",
      },
    },
  })
);

window.GOOGLE_BUTTON_ID = "google-sign-in-button";
window.FACEBOOK_LOGGED = false;

const mapStateToProps = (state) => {
  return {
    logged: state.User.logged,
    newUser: state.User.new,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onMessage: (event, handle) => dispatch(onMessage(event, handle)),
    emitMessage: (event, handle) => dispatch(emitMessage(event, handle)),
    setUser: (user) => dispatch(setUser(user)),
    userSetNew: (bool) => dispatch(userSetNew(bool)),
    setHome: (home) => dispatch(setHome(home)),
    homeSetSlots: (slots) => dispatch(homeSetSlots(slots)),
    homeSetAds: (advertises) => dispatch(homeSetAds(advertises)),
    homeSetNotifications: (notifications) =>
      dispatch(homeSetNotifications(notifications)),
    setConversationPreview: (previews) =>
      dispatch(setConversationPreview(previews)),
  };
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false,
      mail: "",
      password: "",
      remember: true,
      isOpen: false,
      loading: false,
      success: false,
      disabled: false,
    };
  }

  // componentDidMount() {
  /*if (window.FB) {
      //window.FB.XFBML.parse();
      this.checkLoginState();
    }*/
  // if (window.gapi)
  //   if (window.gapi.signin2) {
  //     window.gapi.signin2.render(window.GOOGLE_BUTTON_ID, {
  //       width: "auto",
  //       onsuccess: window.googleLogIn,
  //     });
  //   }
  //if(window.gapi.auth2 && window.gapi.auth2.currentUser){
  //console.log("GOOGLEUSER",window.gapi.auth2.currentUser)
  //window.googleLogIn(window.gapi.auth2.GoogleUser)
  //}
  // }

  componentDidUpdate(prevProps) {
    if (
      prevProps.logged !== this.props.logged ||
      prevProps.newUser !== this.props.newUser
    ) {
      if (this.props.logged === true) {
        document.getElementById("closing_modal").click();
      }
    }
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.logIn();
    }
  };

  logIn = () => {
    this.setState({ disabled: true });
    const { mail, password, remember } = this.state;
    //console.log("stringify " + JSON.stringify({ mail, password, remember }));
    fetcher({
      path: "/api/logIn",
      credentials: true,
      redirect: "follow",
      method: "POST",
      body: { mail, password, remember },
    })
      .then((res) => {
        if (!res.success) {
          const logged = null;
          this.setState({ logged: logged, disabled: false });
        } else {
          res.user.logged = true;
          this.setState({ logged: true });
          setIdGA(res.user._id);
          //console.log("successo loggedin. Utente ID", res.user);
          res.user.tenants.forEach((tenant, index) => {
            if (tenant.user_id) {
              let user_tenant = res.user_tenants.find(
                (x) => x._id == tenant.user_id
              );
              if (user_tenant) {
                //console.log("USER TENANT SET", user_tenant);
                let pickedUser = {};
                pickedUser.value = user_tenant._id;
                pickedUser.image = user_tenant.profile_image;
                pickedUser.image_webp = user_tenant.profile_image_webp;
                pickedUser.location = user_tenant.location;
                pickedUser.age = user_tenant.age;
                pickedUser.type = user_tenant.type;
                pickedUser.label = user_tenant.name + " " + user_tenant.surname;
                res.user.tenants[index].pickedUser = pickedUser;
                res.user.tenants[index].image = user_tenant.profile_image;
                res.user.tenants[index].image_webp =
                  user_tenant.profile_image_webp;
              }
            }
          });
          this.props.setUser(res.user);
          if (res.notifications != null) {
            //console.log("not in login  ", res.notifications);
            this.props.homeSetNotifications(res.notifications);
          }
          if (res.home != null) {
            res.home.tenants.forEach((tenant, i_t) => {
              if (tenant.user_id) {
                let home_tenant = res.home_tenants.find(
                  (x) => x._id == tenant.user_id
                );
                if (home_tenant) {
                  let pickedUser = {};
                  pickedUser.value = home_tenant._id;
                  pickedUser.image = home_tenant.profile_image;
                  pickedUser.image_webp = home_tenant.profile_image_webp;
                  pickedUser.location = home_tenant.location;
                  pickedUser.age = home_tenant.age;
                  pickedUser.type = home_tenant.type;
                  pickedUser.label =
                    home_tenant.name + " " + home_tenant.surname;
                  res.home.tenants[i_t].pickedUser = pickedUser;
                  res.home.tenants[i_t].image = home_tenant.profile_image;
                  res.home.tenants[i_t].image_webp =
                    home_tenant.profile_image_webp;
                }
              }
            });
            if (res.ads != null) {
              res.home.advertises = res.ads;
            }
            this.props.setHome(res.home);

            if (res.slotArray != null) {
              this.props.homeSetSlots(res.slotArray);
            }
          }
          if (res.notifications != null) {
            this.props.homeSetNotifications(res.notifications);
            //console.log("not log", res.notifications);
          }
          if (res.conversations != null) {
            this.props.setConversationPreview(res.conversations);
          }
          if (res.home != null) {
            this.props.emitMessage("login_home", res.home._id);
            this.props.emitMessage("login_user", res.user._id);
          } else this.props.emitMessage("login_user", res.user._id);
          //console.log("logged: " + this.state.logged + "|");
        }
      })
      .catch((e) => console.log("Error during Fetch:", e));
  };

  statusChangeCallback = () => {
    const { /*hash,*/ pathname } = this.props.history.location;
    if (isFacebookApp()) {
      facebookRedirect(
        pathname === "/pubblica-annuncio-stanza-appartamento"
          ? "/pubblica-annuncio-stanza-appartamento"
          : "/"
      );
    } else
      fb((FB) => {
        FB.getLoginStatus((response) => {
          if (response.status === "connected") {
            fbLogIn();
          } else if (response.status === "not_authorized") {
            if (isFacebookApp()) {
              facebookRedirect(
                pathname === "/pubblica-annuncio-stanza-appartamento"
                  ? "/pubblica-annuncio-stanza-appartamento"
                  : "/"
              );
            } else
              FB.login(
                function (response) {
                  fbLogIn();
                },
                { scope: "public_profile,email,user_birthday,user_gender" }
              );
            //console.log("Please log into this app.");
          } else {
            if (isFacebookApp()) {
              facebookRedirect(
                pathname === "/pubblica-annuncio-stanza-appartamento"
                  ? "/pubblica-annuncio-stanza-appartamento"
                  : "/"
              );
            } else
              FB.login(
                function (response) {
                  fbLogIn();
                },
                { scope: "public_profile,email,user_birthday,user_gender" }
              );
            //console.log("Please log into this facebook.");
          }
        });
      });
  };

  /*
  checkLoginState() {
    if (typeof window.FB !== "undefined") {
      window.FB.getLoginStatus();
    }
  }*/

  checkMailState = (event) => {
    this.setState({ mail: event.target.value });
  };

  checkPasswordState = (event) => {
    this.setState({ password: event.target.value });
  };

  checkrememberState = (event) => {
    this.setState({
      remember: event.currentTarget.checked ? true : false,
    });
  };

  render() {
    const { mail, remember, logged, password } = this.state;
    const { inSignup } = this.props;
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className={"col " + (!inSignup ? "" : "nopadding")}>
            <div className="panel panel-default">
              {!inSignup && (
                <div className="panel-heading">
                  <div
                    className="thipi-text-h2 text-center pt-2 pb-2"
                    style={{ lineHeight: 1 }}
                  >
                    {logged != null && this.props.create
                      ? t`Accedi o registrati per continuare`
                      : t`Benvenuto su Thipibo`}
                  </div>
                </div>
              )}
              <div className="panel-body">
                <button
                  id="facebookButton"
                  className="btn btn-sm facebook-btn btn-block"
                  type="button"
                  onClick={this.statusChangeCallback}
                >
                  <picture>
                    <source type="image/webp" srcSet={facebookIconWebp} />
                    <source type="image/png" srcSet={facebookIcon} />
                    {/*eslint-disable-next-line lingui/no-unlocalized-strings*/}
                    <img src={facebookIcon} alt="facebook Logo" />
                  </picture>
                  <span><Trans>Continua con Facebook</Trans></span>
                </button>
                <center className="py-2">
                  <GoogleButton />
                </center>
                <center>
                  <div className="login-or"><Trans>oppure</Trans></div>
                </center>
                {!inSignup && (
                  <form acceptCharset="UTF-8">
                    <div>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="youremail"
                        label={t`E-mail`}
                        type="email"
                        autoComplete="username"
                        onChange={this.checkMailState}
                        onKeyPress={this.handleKeyPress}
                        value={mail}
                        error={logged === null}
                        fullWidth
                        required
                        autoFocus
                      />
                    </div>
                    <div className="mt-2">
                      <TextField
                        variant="outlined"
                        size="small"
                        name="password"
                        label={t`Password`}
                        type="password"
                        autoComplete="current-password"
                        onChange={this.checkPasswordState}
                        onKeyPress={this.handleKeyPress}
                        value={password}
                        error={logged === null}
                        helperText={
                          logged === null ? t`mail o password errati` : null
                        }
                        fullWidth
                        required
                        autoFocus
                      />
                    </div>
                    <div className="mt-2">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={remember}
                            onChange={this.checkrememberState}
                            name="remember"
                            color="primary"
                          />
                        }
                        label={t`Ricordami`}
                      />
                    </div>

                    <Button
                      id="modalLogin"
                      className="btn-block"
                      type="button"
                      disabled={this.state.disabled}
                      ref={(btnLogin) => {
                        this.btnLogin = btnLogin;
                      }}
                      onClick={() => {
                        this.logIn();
                      }}
                    >
                      {this.state.disabled ? <Loader /> : t`Accedi`}
                    </Button>
                    <div className="text-center pb-3">
                      {this.props.children}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
