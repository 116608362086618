import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

const locales_f = () => ({
  "it-IT": t`Italiano`,
  "en-GB": t`Inglese`,
  // "es-ES": t`Spagnolo`,
});
export const locales = locales_f();

export const defaultLocale = "it-IT";

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale: string) {
  const { messages } = await import(`./locales/${locale}.po`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}
